import { Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Table } from "react-bootstrap";
import { CiCircleRemove } from "react-icons/ci";
import Select from 'react-select';
import { debounce } from 'lodash';
import axios from "axios";
import config from "../../../../config/config";
import { apiHeaderToken } from "../../../../config/api_header";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from "react-toastify";


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        height: '44px',
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
};


export default function ApprovalModalApprovalCandidate({ open, setOpen, memberListData }) {
    const [option, setOption] = useState([]);
    const [selectedMember, setSelectedMember] = useState([])
    const [addPeriority, setAddPriority] = useState('')
    const [pen, setPen] = useState(false);
    const [offerApproveMember, setMember] = useState(null)
    const [loadingApproval, setLodingApproval] = useState(false);
    const [docId, setDocId] = useState(null);
    const [loading, setLoading] = useState(false);

    /**
     * @description Get User Detials From the Local Storage - Loggin User Details
     */
    const getUserDetails = JSON.parse(localStorage.getItem('admin_role_user')) ?? {};




    /**
     * @description Added the Handle changes when Select Employee
     * @param {*} option 
     * @set set the Option in Employee 
     */
    const handleChange = (option) => {
        setSelectedMember(option)
    }

    /**
     * @description member filter chanes
     * @param {*} input 
     */
    const handleInputChange = (input) => {
        if (input) {
            setPen(true);
            debouncedFetch(input);
        } else {
            setPen(false);
            setOption([]);
        }
    };

    /**
     * @description Added the Debounce in when fetch the member records
     * @method getMemberList is that whether member is called from
     * @param {*} setpen - is react state which can use to loading state when data is feching
     * @param {Array || any} setOption where we can set the Option when we are setting geching the records
     */
    const debouncedFetch = useCallback(
        debounce((input) => {
            getMemberList(input)
                .then((res) => {
                    setPen(false)
                    setOption(res);
                })
                .catch((err) => {
                    setOption([]);
                });
        }, 500), // Adjust the delay (in milliseconds) as needed
        []
    );

    /**
     * @description fetch the member records
     * @param {*} input which take a input and return the matching records
     * @returns retunr the muteted result in react-select
     */

    const getMemberList = async (input = '') => {
        try {
            let payloads = {
                "keyword": input,
                "page_no": "1",
                "per_page_record": "10",
                "scope_fields": ["employee_code", "name", "email", "mobile_no", "_id", "designation"],
                "profile_status": "Active",
            }
            let response = await axios.post(`${config.API_URL}getEmployeeList`, payloads, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                return response.data?.data?.map((key) => {
                    return {
                        label: `${key?.name} (${key?.employee_code})`,
                        value: key._id,
                        emp: key
                    }
                })
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }

    /**
     * @description handle On menu Open when click to Open the Select Open then Fetch the existing recods
     */
    const handleMenuOpen = () => {
        setPen(true);
        getMemberList("")
            .then((res) => {
                setPen(false)
                setOption(res)
            })
            .catch(err => {
                setPen(false)
                setOption([])
            })
    }

    /**
     * @description handle Priority changes in input when make the Update 
     * @param {*} index 
     * @param {*} newPriority 
     */
    const handlePriorityChange = (index, newPriority) => {
        const updatedMembers = offerApproveMember?.map((member, i) =>
            i === index ? { ...member, priority: newPriority } : member
        );
        setMember(updatedMembers);
    };

    /**
     * @description Handle the send Email with base On the Member and when Added manages and status from the next ->
     * @param {*} item 
     * @returns 
     */

    const getActionButtonByPriority = (item) => {
        const validMembers = offerApproveMember?.filter(member => member?.priority != null);
        const sortedMembers = validMembers?.sort((a, b) => a.priority - b.priority);
        const lowestPriorityMember = sortedMembers?.find(
            (member) => member.approval_status === "Approved"
        );
        const secondLowestMember = sortedMembers?.find(
            (member) => member.priority > (lowestPriorityMember?.priority || 0) && (member.approval_status === "" || member.approval_status === "Pending")
        );

        if (item?.priority === secondLowestMember?.priority && (item?.approval_status === "" || item?.approval_status === "Pending")) {
            return (
                <Button
                    type="button"
                    className="btn btn-success"
                    style={{ height: '40px', fontSize: '10px', color: 'white' }}
                    onClick={() => sendOfferApproval(item)}
                    disabled={loadingApproval}
                >
                    {loadingApproval ? "Sending Approval.." : "Send Mail"}
                </Button>
            );
        }
        if (item?.approval_status === "Approved") {
            return <span>Already Approved</span>;
        }
        if(item?.approval_status === 'Rejected'){
            return <span className="color-red">Already Rejected</span>;
        }
        return <span>No Actions Available</span>;
    };
    /**
     * @description Send Email for Approval when Clicked the Send it's would be sended in one member by member
     * @param {*} item 
     */
    const sendOfferApproval = async (item) => {
        try {
            setLodingApproval(true)
            let payloads = {
                "approval_note_doc_id": docId,
                "employee_id": item?.emp_doc_id,
                "add_by_name": getUserDetails?.name,
                "add_by_mobile": getUserDetails?.mobile_no,
                "add_by_designation": getUserDetails?.designation,
                "add_by_email": getUserDetails?.email
            }

            let response = await axios.post(`${config.API_URL}sendJobOfferApprovalMailToMember`, payloads, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                toast.success(response.data.message);
                getApprovalMemberListById(docId)
                setLodingApproval(false)
            } else {
                toast.error(response.data.message);
                setLodingApproval(false)
            }
        } catch (error) {
            toast.error(error?.response.data?.message || error.message || 'Someting Went Wrong');
            setLodingApproval(false)
        }
    }
    /**
     * @description Handle The Removed the Member List when clicked to removed -
     * @param {*} item 
     */
    const handleRemove = (item) => {
        let deletedItem = offerApproveMember?.filter((data) => data?._id !== item?._id)?.map((item) => {
            if (item?.emp_doc_id === 'NA') {
                return {
                    id: item?.emp_doc_id,
                    priority: 0
                }
            }
            return {
                id: item?.emp_doc_id,
                priority: item.priority
            }
        })
        // Delete the member 
        memberAction(deletedItem)
            .then((res) => {
                if (res.status === 200) {
                    setMember(offerApproveMember?.filter((data) => data?._id !== item?._id))
                    getApprovalMemberListById(docId)
                }
            })
    }
    /**
     * @description This is Member Action Called && Form the Curd Action -
     * @param {Array || anyy} memberList 
     * @returns 
     */
    const memberAction = async (memberList) => {
        try {
            // docId
            let paylods = {
                "approval_note_doc_id": docId,
                "employee_ids": memberList,
                "add_by_name": getUserDetails?.name,
                "add_by_mobile": getUserDetails?.mobile_no,
                "add_by_designation": getUserDetails?.designation,
                "add_by_email": getUserDetails?.email
            }
            let response = await axios.post(`${config.API_URL}addJobOfferApprovalMember`, paylods, apiHeaderToken(config.API_TOKEN))
            if (response.status === 200) {
                toast.success(response.data?.message)
                return response
            } else {
                toast.error(response.data?.message)
                return response
            }
        } catch (error) {
            toast.error(error?.response.data?.message || error.message || 'Someting Went Wrong');
            return error?.response.data?.message || error.message || 'Someting Went Wrong';
        }
    }
    /**
     * @description Add member with in Approval List ->  
     * @returns 
     */
    const addMember = () => {
        if (!selectedMember) {
            return toast.warn("Please Select the Member");
        }
        if (!addPeriority) {
            return toast.warn("Please Add Priority");
        }

        // Called the here Api After that update the member =;
        let payload = offerApproveMember?.map((item) => {
            if (item?.emp_doc_id === 'NA') {
                return {
                    id: item?.emp_doc_id,
                    priority: 0
                }
            }
            return {
                id: item?.emp_doc_id,
                priority: item.priority
            }
        })

        memberAction([...payload, { id: selectedMember?.emp?._id, priority: parseInt(addPeriority) }])
            .then((res) => {
                if (res.status === 200) {
                    setSelectedMember(null)
                    setAddPriority("")
                    getApprovalMemberListById(docId)
                }
            })
    };

    /**
     * @description Hanlde Update Member
     * @param {Object} item
     */
    const handleUpdateMember = async () => {
        let payload = offerApproveMember?.map((item) => {
            if (item?.emp_doc_id === 'NA') {
                return {
                    id: item?.emp_doc_id,
                    priority: 0
                }
            }
            return {
                id: item?.emp_doc_id,
                priority: item.priority
            }
        })
        await memberAction(payload)
        getApprovalMemberListById(docId)
    }

    /**
     * @description On the First Render Called the Member List Data when Make changes ->
     */

    useEffect(() => {
        if (memberListData?.approval_note_doc_id) {
            getApprovalMemberListById(memberListData?.approval_note_doc_id)
            setDocId(memberListData?.approval_note_doc_id)
        }
    }, [memberListData])

    /**
     * @description Get the Member List Data by Docs Id ->
     * @param {*} id 
     * @returns set the member State -
     */
    const getApprovalMemberListById = (id) => {
        const payload = {
            "approval_note_doc_id": id,
            "scope_fields": []
        }
        setLoading(true)
        axios.post(`${config.API_URL}getAppraisalNoteById`, payload, apiHeaderToken(config.API_TOKEN))
            .then((res) => {
                if (res.status === 200) {
                    setMember(res.data?.data?.panel_members_list)
                    setLoading(false)
                } else {
                    console.log(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }


    return (
        <>
            <Modal
                show={open}
                onHide={() => setOpen(false)}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Set Offer Amount For Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-12">
                        <Row>
                            <Col sm={4}>
                                <Select
                                    options={option}
                                    placeholder="Select Member"
                                    isSearchable
                                    value={selectedMember}
                                    onChange={handleChange}
                                    onInputChange={handleInputChange}
                                    onMenuOpen={handleMenuOpen}
                                    isLoading={pen}
                                    styles={customStyles}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Priority"
                                    value={addPeriority}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (/^\d*$/.test(newValue)) {
                                            setAddPriority(newValue) // Parse or fallback to empty
                                        }
                                    }}
                                    className="form-control"
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                    <button type="button" class="sitebtn btn btn-primary ratebtn" onClick={addMember}> <CheckCircleIcon /> Add Member </button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <>
                        <div className="modaltbl mt-3">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Sno.</th>
                                        <th>Panel Member</th>
                                        <th>Priority</th>
                                        <th>Status</th>
                                        <th>Send Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ? <tr> <td colSpan={6} className="text-center"> Loading...... </td> </tr> :
                                            offerApproveMember && offerApproveMember?.length > 0 ?
                                                offerApproveMember?.sort((a,b) => a?.priority - b?.priority)?.map((item, index) => {
                                                    return (
                                                        <tr key={item?.employee_doc_id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className='d-flex flex-column align-items-start gap-1'>
                                                                    <span>{item?.employee_code}</span>
                                                                    <span>{item?.name}</span>
                                                                    <span>{item?.designation}</span>
                                                                </div>
                                                            </td>
                                                            <td width={'18%'}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={item?.priority}
                                                                    readOnly={(item?.approval_status === "Approved" || item?.approval_status === "Pending")}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        if (/^\d*$/.test(newValue)) {
                                                                            handlePriorityChange(index, parseInt(newValue, 10) || ""); // Parse or fallback to empty
                                                                        }
                                                                    }}
                                                                    className="form-control w-50"
                                                                />
                                                            </td>
                                                            <td>{item?.approval_status || "-"}</td>
                                                            <td>{item?.send_mail_date ? moment(item?.send_mail_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    <span className="" style={{ height: '44px' }}>{getActionButtonByPriority(item)}</span>
                                                                    {
                                                                        item?.approval_status === "" &&
                                                                        <OverlayTrigger
                                                                            placement="top" // Tooltip position: 'top', 'bottom', 'left', or 'right'
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-delete-${item.id || Math.random()}`}>
                                                                                    Delete Member
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span onClick={() => handleRemove(item)} style={{ cursor: 'pointer' }}>
                                                                                <CiCircleRemove size={25} color="red" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                            <tr className='text-center'>
                                                    <td colSpan={6} className='text-center'>No Record Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-sm-12 text-center">
                            <button style={{ marginTop: '36px' }} type="button" class="sitebtn btn btn-primary ratebtn" onClick={handleUpdateMember}> <CheckCircleIcon /> Update </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}