import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

export default function EmployeeTable({ formData, searchQuery }) {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const handleProfileRedirect = (id) => {
        localStorage.setItem("onBoardingId", id);
        navigate('/people-profile');
    };
    const handleProfileEditRedirect = (id) => {
        localStorage.setItem("onBoardingId", id);
        navigate('/onboarding');
    };


    useEffect(() => {
        const data = searchQuery || [];
        // Map API data to the format expected by the DataGrid
        const mappedRows = data.map((item, index) => {
            // Construct the location from the permanent_address object
            const location = (item.branch && item.branch.length > 0) ? item.branch.join(', ') : "N/A";
            return {
                id: index + 1,
                _id: item._id,
                Ec: item.employee_code || item._id, // Assuming "_id" is equivalent to "EC No."
                candidateInfo: {
                    name: item.name || "N/A",
                    status: item.profile_status || "N/A",
                    email: item.email || "N/A",
                    phone: item.mobile_no || "N/A",
                },
                "Date of Joining": formatDateToDDMMYYYY(item.joining_date) || "N/A",
                Designation: item.designation || "N/A",
                Project: item.project_name || "N/A", // Adjust if you have project info
                Department: item.department || "N/A", // Adjust if you have department info
                Location: location !== ", ," ? location : "N/A", // Set "N/A" if location is not properly formed
                Batch_id:item?.batch_id ? item?.batch_id : "N/A"
            };
        });

        setRows(mappedRows);
    }, [searchQuery]); // Add searchQuery as a dependency to update rows when searchQuery changes

    function formatDateToDDMMYYYY(dateString) {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
    }

    const columns = [
        {
            field: "Ec",
            headerName: "EC No.",
            width: 100,
            renderCell: (params) => {
                return <Link to={"#"} onClick={(e) => {
                    e.preventDefault();
                    handleProfileRedirect(params.row._id);
                }}>{params.row?.Ec}</Link>;
            },
            // flex: 0.8
        },
        {
            field: "Name",
            headerName: "Name",
            width:200,
            renderCell: (params) => (
                <div className="candinfo">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleProfileRedirect(params.row._id);
                        }}
                    >
                        <p className="color-blue overflow-hidden">{params.row?.candidateInfo?.name}</p>
                    </a>
                    <span className="statustag">{params.row?.candidateInfo?.status}</span>
                </div>
            ),
        },
        {
            field: "Batch_id",
            headerName: "Batch Id",
            width:80,
        },
        {
            field: "Email & Phone Number",
            headerName: "Email & Phone Number",
            width:200,
            renderCell: (params) => (
                <div className="d-flex flex-column data-flexer-with-border">
                    <span className="text-start overflow-hidden">{params.row?.candidateInfo?.email}</span>
                    <span className="text-start">{params.row?.candidateInfo?.phone}</span>
                </div>
            ),
        },
        {
            field: "Date of Joining",
            headerName: "Date of Joining",
            width:150,
        },
        {
            field: "Designation",
            headerName: "Designation",
            width: 200,
            renderCell: (params) => (
                <p className="text-start lineBreack">{params.row?.Designation}</p>
            ),
        },
        {
            field: "Project",
            headerName: "Project",
            renderCell: (params) => (
                <p className="text-start lineBreack overflow-hidden">{params.row?.Project}</p>
            ),
            width:150,
        },
        {
            field: "Department",
            headerName: "Department",
            width:150,
            renderCell: (params) => (
                <p className="text-start lineBreack overflow-hidden">{params.row?.Department}</p>
            ),
        },
        {
            field: "Location",
            headerName: "Location",
            width:150,
            renderCell: (params) => (
                <p className="text-start lineBreack overflow-hidden">{params.row?.Location}</p>
            ),
        },
        {
            field: "Edit",
            headerName: "Action",
            renderCell: (params) => (
                <div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            handleProfileEditRedirect(params.row._id);
                        }}
                        type='button'
                        className='btn btn-primary'
                        style={{ height: "35px", lineHeight: "12px" }}
                    >
                        <FaRegEdit className='fs-5 text-center' />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <div className="w-100 hide-check mt-3">
            <DataGrid
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                rowHeight={80}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
            />
        </div>
    );
}
