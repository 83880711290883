import { Box, Button } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, Accordion, Table } from 'react-bootstrap';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { CamelCases } from '../../../../utils/common';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../../../config/config';
import { apiHeaderToken } from '../../../../config/api_header';


const RecordsModal = ({ openModalApprovalHistory, handleClosedShowApprovalHistory, data }) => {

  const getUserDetails = JSON.parse(localStorage.getItem('admin_role_user')) ?? {};
  const [loading , setLoading] = useState('')


  const haringStatus = (e, can_di, status) => {
    e.preventDefault();

    let paylods = {
      "candidate_id": can_di?.cand_doc_id,
      "applied_job_id": can_di?.applied_job_doc_id,
      "hiring_status": status,
      "add_by_name": getUserDetails?.name,
      "add_by_mobile": getUserDetails?.mobile_no,
      "add_by_designation": getUserDetails?.designation,
      "add_by_email": getUserDetails?.email
    }

    setLoading(status)

    axios.post(`${config.API_URL}updateHireStatus`, paylods, apiHeaderToken(config.API_TOKEN))
      .then((response) => {
        if (response.status === 200) {
           toast.success(response.data.message)
           setLoading('')
        }else {
          toast.success(response.data.message)
          setLoading('')

        }
      })
      .catch(err => {
        toast.error(err.response.data.message || err?.message)
        setLoading('')
      })
  }

  return (
    <>

      <Modal show={openModalApprovalHistory} onHide={handleClosedShowApprovalHistory} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Approval History</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Accordion defaultActiveKey="0">
            {data?.candidate_list?.map((item, index) => (
              <>
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>Candidate Name - {CamelCases(item?.name)}</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.approval_history?.length > 0 ? (
                          item.approval_history.map((member, idx) => {
                            const memberDetails = data?.panel_members_list?.find(
                              (panelMember) => panelMember?.emp_doc_id === member?.emp_doc_id
                            );
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{CamelCases(memberDetails?.name) || "N/A"}</td>
                                <td>{memberDetails?.designation || "N/A"}</td>
                                <td>{member?.approval_status || "N/A"}</td>
                                <td>{member?.approved_date ? moment(member?.approved_date).format('DD/MM/YYYY') : "N/A"}</td>
                                <td>{member?.remark || "N/A"}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      gap: 3,
                      marginTop: 2
                    }}>
                      <Button
                        color="success"
                        variant="contained"
                        startIcon={<CheckCircleIcon />}
                        onClick={(e) => haringStatus(e , item , 'Approved')}
                        disabled={loading === 'Approved'}
                      >
                        Approve
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        startIcon={<PauseCircleIcon />}
                        onClick={(e) => haringStatus(e , item , 'Hold')}
                        disabled={loading === 'Hold'}
                      >
                        Hold
                      </Button>
                    </Box>
                  </Accordion.Body>
                </Accordion.Item>



              </>
            ))}
          </Accordion>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedShowApprovalHistory}> Close </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecordsModal;