import logo from '../images/logo.png';

let config = {}

let user = JSON.parse(localStorage.getItem('admin_role_user')) || {};
config['API_TOKEN'] = `${user?.token}`;
config['size'] = 500;


//Live
config['BASE_URL'] = 'https://api-hrms.dtsmis.in:3008/';
config['API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/admin/';
config['CANDIDATE_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/candidate/';
config['PANEL_URL'] = 'https://api-hrms.dtsmis.in:3008/'
config['IMAGE_PATH'] = 'https://api-hrms.dtsmis.in:3008/public/uploads/';
config['GLOB_API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/global/';
config['LOGO_PATH'] = logo;


// duplex URL Config

// config['BASE_URL'] = 'https://hrms.duplextech.com:3018/';
// config['API_URL'] = ' https://apis.duplextech.com:3018/v1/admin/';
// config['GLOB_API_URL'] = 'https://apis.duplextech.com:3018/v1/global/';
// config['IMAGE_PATH'] = 'https://apis.duplextech.com:3018/public/uploads/';
// config['CANDIDATE_URL'] = 'https://apis.duplextech.com:3018/v1/candidate/';
// config['FRONT_URL'] = ' https://career.duplextech.com/';
// config['LOGO_PATH'] = 'https://res.cloudinary.com/duplex-tech/image/upload/v1689317653/newDuplex/setting/eviirl8exl9agdov3ej6.png'
// config['COMPANY_NAME'] = 'Duplex Technologies Services Pvt. Ltd ';  
// config['PANEL_NAME'] = 'Candidate Login';
// config['HELP_URL'] = 'https://duplextech.com/contact-us.html';
// config['PRIVACY_URL'] = 'https://duplextech.com/privacy-policy.html';
// config['TERMS_URL'] = 'https://duplextech.com/terms-and-conditions.html';
// config['GOOGLE_MAP_KEY'] = 'AIzaSyDjkMMcPgRXYBLKaUf6finQlPubrbNbvbw'; 


// config['BASE_URL'] = 'https://hrapi.hlfppt.org/';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/admin/';
// config['CANDIDATE_URL'] = 'https://hrapi.hlfppt.org/v1/candidate/';
// config['PANEL_URL'] = 'https://hrapi.hlfppt.org/'
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['GLOB_API_URL'] = 'https://hrapi.hlfppt.org/v1/global/';
// config['LOGO_PATH'] = logo;


 

config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HRMS Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'privacy-policy';
config['TERMS_URL'] = 'terms-conditions';
config['COMPANY_NAME'] = 'HLFPPT HRMS';
config['GOOGLE_MAP_KEY'] = 'AIzaSyDjkMMcPgRXYBLKaUf6finQlPubrbNbvbw';


export default config;