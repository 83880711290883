import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import GoBackButton from '../goBack/GoBackButton';
import Box from "@mui/material/Box";

// import JobCards from "./JobCards";
import JobCards from "../ats/JobCards";
// import JobCardsArchived from "./JobCardsArchived";
import JobCardsArchived from "../ats/JobCardsArchived";
import AllHeaders from "../partials/AllHeaders";
import { useSelector, useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';

import { GetJobList , AchievedJobList , ExpiredJobList } from "../slices/AtsSlices/getJobListSlice";
import { InfinitySpin } from 'react-loader-spinner'
import { FetchProjectListDropDown } from "../slices/ProjectListDropDown/ProjectListDropdownSlice";
import FormControl from "@mui/material/FormControl";
import { GrPowerReset } from "react-icons/gr";
import { Button } from "react-bootstrap";


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        height: '46px',
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
};



function JobList() {
    const dispatch = useDispatch();
    const PublishedJobList = useSelector((state) => state.getJobsList.getJobList)
    const {achievedJobList , expiredJobList} = useSelector((state) => state.getJobsList)
    const [option, setOptions] = useState([]);
    const [projectListOption, setProjectOptions] = useState([]);
    const [Rotate , setRotateIcon] = useState(false);    

    const handleResetOption = (e) => {
        e.preventDefault()
        // setRotateIcon(true);
        setProjectOptions('')
        let Payloads = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"
            ],
        }
        dispatch(GetJobList(Payloads)).unwrap()
        let PayloadsOFExpired = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "status":"Expired",
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"
            ],
        }
        dispatch(ExpiredJobList(PayloadsOFExpired))
    }

    useEffect(() => {
        let Payloads = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"
            ],
        }
        dispatch(GetJobList(Payloads));

        let PayloadsOFExpired = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "status":"Expired",
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"
            ],
        }
        dispatch(ExpiredJobList(PayloadsOFExpired))
    }, [dispatch]);

    const projectLoadOption = async (input) => {
        const result = await dispatch(FetchProjectListDropDown(input)).unwrap();
        return result;
    }
    const projectMenuOpen = async () => {
        const result = await dispatch(FetchProjectListDropDown('')).unwrap();
        setOptions(result);
    }
    const handleProjectChanges = (option) => {
        setProjectOptions(option);
        let Payloads = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "project_id": option?.value,
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"

            ],
        }
        dispatch(GetJobList(Payloads));
        let PayloadsOFExpired = {
            "keyword": "",
            "department": "",
            "job_title": "",
            "location": "",
            "job_type": "",
            "salary_range": "",
            "page_no": "1",
            "project_id": option?.value,
            "status":"Expired",
            "per_page_record": "100",
            "scope_fields": [
                "_id",
                "project_name",
                "department",
                "job_title",
                "job_type",
                "experience",
                "location",
                "salary_range",
                "status",
                "working",
                "deadline",
                "form_candidates",
                "total_vacancy",
                "available_vacancy",
                "add_date",
                "designation"
            ],
        }
        dispatch(ExpiredJobList(PayloadsOFExpired))
    }

    return (
        <>
        {/* <AllHeaders /> */}
            <div className="maincontent">
                <div className="container" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="hrhdng">
                            <h2>Posted Jobs listing</h2>
                            <p className="mb-0 text-start">
                                Active and Archived job listing
                            </p>
                        </div>
                        <div className="hrhdng d-flex">
                            <Box sx={{ minWidth: 400 }}>
                                <FormControl fullWidth>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        defaultValue={option}
                                        loadOptions={projectLoadOption}
                                        value={projectListOption}
                                        onMenuOpen={projectMenuOpen}
                                        placeholder="Select Project"
                                        onChange={handleProjectChanges}
                                        classNamePrefix="react-select"
                                        styles={customStyles}
                                    />
                                </FormControl>
                            </Box>
                            <Button className="p-2" style={{marginLeft:'10px'}} onClick={handleResetOption}> 
                               <GrPowerReset size={25}  />
                            </Button>
                        </div>
                    </div>
                    <div className="row mt-4 gap-4">
                        <div className="col-lg-12">
                            <div className="postedjobs">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="first"
                                    fill
                                >
                                    <Nav
                                        variant="pills"
                                        className="flex-row border-full d-flex justify-content-between align-items-end"
                                    >
                                        <div className="d-flex flex-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Active Jobs ({PublishedJobList.status === 'success' && PublishedJobList.data.filter((job) => job.status === 'Published').length})</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    Archieved Jobs ({PublishedJobList.status === 'success' && PublishedJobList.data.filter((job) => job.status === 'Archived').length})
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">
                                                  Expired Jobs ({expiredJobList.status === 'success' && expiredJobList.data.length})
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </Nav>
                                    <Tab.Content className="contere">
                                        <Tab.Pane eventKey="first">
                                            <div className="d-flex flex-column gap-2 mt-1 scroller-content">
                                                {
                                                    PublishedJobList.status === 'loading' ?
                                                        <div className="d-flex align-content-center justify-content-center">
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#4fa94d"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        </div> :
                                                        PublishedJobList.status === 'success' &&
                                                        PublishedJobList.data.length !== 0 &&
                                                        PublishedJobList.data.map((value, index) => {
                                                            if (value?.status === 'Published') {
                                                                return (
                                                                    <JobCards value={value} />
                                                                )
                                                            }
                                                            return null;
                                                        })
                                                }
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="d-flex flex-column gap-2 mt-1 scroller-content">
                                                {
                                                    PublishedJobList.status === 'loading' ?
                                                        <div className="d-flex align-content-center justify-content-center">
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#4fa94d"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        </div> :
                                                        PublishedJobList.status === 'success' &&
                                                        PublishedJobList.data.length !== 0 &&
                                                        PublishedJobList.data.map((value, index) => {
                                                            if (value?.status === 'Archived') {
                                                                return (
                                                                    <JobCardsArchived value={value} />
                                                                )
                                                            }
                                                            return null;
                                                        })
                                                }
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="d-flex flex-column gap-2 mt-1 scroller-content">
                                                {
                                                    expiredJobList?.status === 'loading' ?
                                                        <div className="d-flex align-content-center justify-content-center">
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#4fa94d"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        </div> :
                                                        expiredJobList?.status === 'success' &&
                                                        expiredJobList.data.length !== 0 &&
                                                        expiredJobList.data.map((value, index) => {
                                                                return (
                                                                    <JobCardsArchived value={value} />
                                                                )
                                                        })
                                                }
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default JobList;
