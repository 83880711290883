import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { InfinitySpin } from 'react-loader-spinner'
import GoBackButton from "../goBack/GoBackButton";
import EmployeeTable from "./EmployeeTable";
import AllHeaders from "../partials/AllHeaders";
import { getEmployeeAllList } from "../../employee/helper/Api_Helper";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FetchProjectListDropDown } from "../slices/ProjectListDropDown/ProjectListDropdownSlice";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import AsyncSelect from 'react-select/async';
import { useNavigate } from "react-router-dom";





const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
    '&:hover': {
      borderColor: '#D2C9FF',
    },
    height: '44px',
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: '1px solid #D2C9FF',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #D2C9FF',
    color: state.isSelected ? '#fff' : '#000',
    backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
    '&:hover': {
      backgroundColor: '#80CBC4',
      color: '#fff',
    },
  }),
};

const EmployeeList = () => {
  const [activeEmployee, setActiveEmployee] = useState(0);
  const [onRoleEmployee, setOnRoleEmployee] = useState(0);
  const [onContractEmployee, setOnContractEmployee] = useState(0);
  const [empaneledEmployee, setEmpaneledEmployee] = useState(0);
  const [dismissedEmployee, setDismissedEmployee] = useState(0);
  const [pendingEmployee, setPendingEmployee] = useState(0);
  const [openLoader, setOpenLoader] = useState(false)
  const navigate  = useNavigate();

  const dispatch = useDispatch();
  const [option, setOption] = useState([]);
  const [projectList, setProjectOptions] = useState(null);

  const [activeEmployeeData, setActiveEmployeeData] = useState([]);
  const [onRoleEmployeeData, setOnRoleEmployeeData] = useState([]);
  const [onContractEmployeeData, setOnContractEmployeeData] = useState([]);
  const [empaneledEmployeeData, setEmpaneledEmployeeData] = useState([]);
  const [dismissedEmployeeData, setDismissedEmployeeData] = useState([]);
  const [pendingEmployeeData, setPendingEmployeeData] = useState([]);
  const [searchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    if(searchParams.get('project_id') && searchParams.get('project_name') ){
     setProjectOptions({value:searchParams.get('project_id') , label:searchParams.get('project_name')})
    }
 } , [searchParams])

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      setOpenLoader(true)
      try {
        const response = await getEmployeeAllList('', searchQuery, searchParams.get('type') , projectList , searchParams.get('project_id'));
        if (response.status) {
          // let employees = []
          const employees = response.data ?? [];
          const allCount = employees.filter(emp => emp.profile_status === "Active").length;
          const onRoleCount = employees.filter(emp => emp.employee_type === "onRole").length;
          const onContractCount = employees.filter(emp => emp.employee_type === "onContract").length;
          const empaneledCount = employees.filter(emp => emp.employee_type === "emPanelled").length;
          const dismissedCount = employees.filter(emp => emp.profile_status === "Dismissed").length;
          const pendingCount = employees.filter(emp => emp.profile_status === "Pending").length;

          // Update state
          setActiveEmployee(allCount);
          setOnRoleEmployee(onRoleCount);
          setOnContractEmployee(onContractCount);
          setEmpaneledEmployee(empaneledCount);
          setDismissedEmployee(dismissedCount);
          setPendingEmployee(pendingCount);

          // Filter employees based on profile status
          const allData = employees.filter(emp => emp.profile_status === "Active");
          const onRoleData = employees.filter(emp => emp.employee_type === "onRole");
          const onContractData = employees.filter(emp => emp.employee_type === "onContract");
          const empaneledData = employees.filter(emp => emp.employee_type === "emPanelled");
          const dismissedData = employees.filter(emp => emp.profile_status === "Dismissed");
          const pendingData = employees.filter(emp => emp.profile_status === "Pending");

          // Update state
          setActiveEmployeeData(allData);
          setOnRoleEmployeeData(onRoleData);
          setOnContractEmployeeData(onContractData);
          setEmpaneledEmployeeData(empaneledData);
          setDismissedEmployeeData(dismissedData);
          setPendingEmployeeData(pendingData);
          setOpenLoader(false)
        } else {
          // Handle API error
          console.error(response.message);
          setOpenLoader(false)
        }
      } catch (error) {
        setOpenLoader(false)
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();

  }, [searchQuery, projectList, searchParams]); // Fetch data whenever searchQuery changes

  useEffect(() => {
    localStorage.removeItem("clfFormData");
    localStorage.removeItem("onBoardingId");
    localStorage.removeItem("PfInfoFormData");
    localStorage.removeItem("residenceFormData");
    localStorage.removeItem("employeeFormData");
    localStorage.removeItem("onBoardingId");
    localStorage.removeItem("permanentFormData");
    localStorage.removeItem("residenceFormData");
    localStorage.removeItem("onBoardingbudget_estimate_list");
    localStorage.removeItem("onBoardingLocation");
    localStorage.removeItem("onBoardingId");
  })




  /********************** Project List Dropdown ********************/
  const projectLoadOption = async (input) => {
    const result = await dispatch(FetchProjectListDropDown(input)).unwrap();
    return result;
  }
  const projectMenuOpen = async () => {
    const result = await dispatch(FetchProjectListDropDown('')).unwrap();
    setOption(result);
  }
  const handleProjectChanges = (option) => {
    setProjectOptions(option);
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNewOnBoard = () => {
      localStorage.removeItem('onBoardingbudget_estimate_list')
      localStorage.removeItem('onBoardingLocation')
      localStorage.removeItem('clfFormData')
      localStorage.removeItem('employeeFormData')
      localStorage.removeItem('PfInfoFormData')
      navigate('/onboarding')
  }


  function check(eventKey) {
    setOpenLoader(true);
    setTimeout(() => {
      setOpenLoader(false);
    }, 900);
  }
  return (
    <>
      {/* <AllHeaders /> */}
      <div className="maincontent">
        <div className="container" >
          <GoBackButton />
          <div className="hrhdng">
            <h2>People</h2>
            <p>Add Employee and Contractors</p>
            <div className="d-flex flex-row gap-5 w-100 my-3">
              <Box sx={{ width: 300 }}>
                <FormControl fullWidth>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={option}
                    loadOptions={projectLoadOption}
                    value={projectList}
                    onMenuOpen={projectMenuOpen}
                    placeholder="Select Project"
                    onChange={handleProjectChanges}
                    classNamePrefix="react-select"
                    styles={customStyles}
                  />
                </FormControl>
              </Box>
              <InputGroup className="input-width">
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-gray-light pe-0 "
                >
                  <IoMdSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search name, email or employee ID"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="bg-gray-light bor-left ps-1 height-input"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </InputGroup>

           
                <button onClick={handleNewOnBoard} className="btn onboard d-flex flex-row gap-2 align-items-center rounded-1 px-3">
                  Onboard New
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <rect width="16" height="16" fill="#BFE7FA" />
                    <path
                      d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM8 4C8.14834 4 8.29334 4.04399 8.41668 4.1264C8.54002 4.20881 8.63615 4.32594 8.69291 4.46299C8.74968 4.60003 8.76453 4.75083 8.73559 4.89632C8.70665 5.0418 8.63522 5.17544 8.53033 5.28033C8.42544 5.38522 8.29181 5.45665 8.14632 5.48559C8.00083 5.51453 7.85004 5.49968 7.71299 5.44291C7.57595 5.38614 7.45881 5.29001 7.3764 5.16668C7.29399 5.04334 7.25 4.89834 7.25 4.75C7.25 4.55109 7.32902 4.36032 7.46967 4.21967C7.61033 4.07902 7.80109 4 8 4ZM10 12.0625H6V10.9375H7.4375V8.0625H6.5V6.9375H8.5625V10.9375H10V12.0625Z"
                      fill="#155674"
                    />
                  </svg>
                </button>
                
            </div>
            <Tab.Container
              id="left-tabs-example "
              className=" "
              defaultActiveKey="first"
            >
              <Nav
                variant="pills"
                className="flex-row postedjobs widthcomp widthfuller w-100 border-full"
              >
                <Nav.Item>
                  <Nav.Link onClick={() => check('first')} eventKey="first">Active Employee(s) ({activeEmployee})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => check('second')} eventKey="second">On-Role ({onRoleEmployee})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => check('third')} eventKey="third">On-Consultant ({onContractEmployee})</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link onClick={() => check('four')} eventKey="four">Empaneled ({empaneledEmployee})</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link onClick={() => check('five')} eventKey="five">Dismissed ({dismissedEmployee})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => check('six')} eventKey="six">Pending ({pendingEmployee})</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="w-100">
                {openLoader ? (
                  <div className="d-flex justify-content-center">
                    <InfinitySpin width="200" color="#0092FE" />
                  </div>
                ) : (
                  <>
                    <Tab.Pane eventKey="first">
                      <EmployeeTable formData={'Active'} searchQuery={activeEmployeeData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <EmployeeTable formData={'On-role'} searchQuery={onRoleEmployeeData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <EmployeeTable formData={'On-Contract'} searchQuery={onContractEmployeeData} />
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="four">
                      <EmployeeTable formData={'Empaneled'} searchQuery={empaneledEmployeeData} />
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="five">
                      <EmployeeTable formData={'Dismissed'} searchQuery={dismissedEmployeeData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                      <EmployeeTable formData={'Pending'} searchQuery={pendingEmployeeData} />
                    </Tab.Pane>
                  </>
                )}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
