import React from 'react';
import GoBackButton from '../goBack/GoBackButton';
import { Link } from 'react-router-dom';
import RequisitionTable from './RequisitionTable';

const ListManpowerRequisition = () => {

    return (
        <>
            {/* <AllHeaders /> */}
            <div className="maincontent">
                <div className="container hrdashboard" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className='dflexbtwn'>
                        <div className='pagename'>
                            <h3>List of Requisition Form</h3>
                            <p>Requisition raise request Lisitng  </p>
                        </div>
                        <div className='linkbtn'>
                            <Link to='/manpower-acquisition'><button className='purplbtn'>Add</button></Link>
                        </div>
                    </div>
                    <div className='row'>
                        <RequisitionTable />
                    </div>
                </div>
            </div>
        </>
    )
}


export default ListManpowerRequisition;
