import React, { useEffect, useState } from "react";
import GoBackButton from '../goBack/GoBackButton';
import AllHeaders from "../partials/AllHeaders";
import { useSelector, useDispatch } from "react-redux";
// JobCardsCandidateTabs
import JobCardsCandidateTabs from "../job/JobCartsDetails/JobsCartsCandidateTabs";
import { FetchAppliedCandidateDetails, setSelecteJobList } from '../slices/AppliedJobCandidates/JobAppliedCandidateSlice'
import { Col, Form, Row } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { GetDesignationWiseJobList } from "../slices/AtsSlices/getJobListSlice";
import { Button } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
    '&:hover': {
      borderColor: '#D2C9FF',
    },
    height: '44px',
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: '1px solid #D2C9FF',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #D2C9FF',
    color: state.isSelected ? '#fff' : '#000',
    backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
    '&:hover': {
      backgroundColor: '#80CBC4',
      color: '#fff',
    },
  }),
};



const CandidateListing = () => {
  const AppliedJobs = useSelector((state) => state.appliedJobList.AppliedCandidate)
  const dispatch = useDispatch()
  const [option, setOptions] = useState(null);
  const [jobSearchValue, setJobSearchValue] = useState();

  useEffect(() => {
    dispatch(FetchAppliedCandidateDetails());
  }, [dispatch])

  // Fetch the Job List Option ->   
  const jobLoadOption = async () => {
    let Payloads = {
      "keyword": "",
      "department": "",
      "job_title": "",
      "location": "",
      "page_no": "1",
      "per_page_record": "100",
      "scope_fields": [
        "_id",
        "job_title",
        "project_id"
      ],
    }

    // setCandidatesDetials

    const result = await dispatch(GetDesignationWiseJobList(Payloads)).unwrap();
    setOptions(result?.map((item) => {
      return {
        id: item._id,
        label: item?.job_title,
        value: item?._id,
        project_id:item?.project_id
      }
    }))
  }

  const JobInputChanges = async (input) => {
    let Payloads = {
      "keyword": input,
      "department": "",
      "job_title": "",
      "location": "",
      "page_no": "1",
      "per_page_record": "100",
      "scope_fields": [
        "_id",
        "job_title",
        "project_id"
      ],
    }

    const result = await dispatch(GetDesignationWiseJobList(Payloads)).unwrap();
    return result?.map((item) => {
      return {
        id: item._id,
        label: item?.job_title,
        value: item?._id,
        project_id:item?.project_id
      }
    })
  }

  // Handle Filter By Job Selected ->
  const FilterByJobSelected = async (option) => {
     setJobSearchValue(option)
     dispatch(setSelecteJobList(option))
     dispatch(FetchAppliedCandidateDetails(option?.value));
  }

  const handleReset = () => {
    dispatch(FetchAppliedCandidateDetails());
    dispatch(setSelecteJobList(null))
    setJobSearchValue(null)
  }

  return (
    <>
      {/* <AllHeaders/> */}
      <div className="maincontent">
        <div className="container" data-aos="fade-in" data-aos-duration="3000">
          <GoBackButton />
          <div class="d-flex justify-content-between align-items-center my-3">
            <div className="hrhdng">
              <h2 class="mb-0">Total Candidates ({AppliedJobs.status === 'success' && AppliedJobs.data?.length})</h2>
              <p class="mb-0 text-start">
                Potential Candidate tracking and management
              </p>
            </div>
            <Row className="w-50">
              <Col sm={12}>
                <Form.Group className="mb-3">
                  {/* <Form.Label>Job List</Form.Label> */}
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={option}
                    loadOptions={JobInputChanges}
                    value={jobSearchValue}
                    onMenuOpen={jobLoadOption}
                    placeholder="Filter By Job"
                    onChange={FilterByJobSelected}
                    classNamePrefix="react-select"
                    styles={customStyles}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginRight: '20px' }}>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<RestartAltIcon />}
                    onClick={handleReset}
                    disabled={!jobSearchValue}
                  >
                    Reset
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <JobCardsCandidateTabs />
        </div>
      </div>
    </>
  );
}

export default CandidateListing;