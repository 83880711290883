import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LuPlus } from "react-icons/lu";
import { IoMdCloseCircleOutline } from "react-icons/io";
import ProjectBox from './ProjectBox';
import GoBackButton from '../goBack/GoBackButton';
import AllHeaders from '../partials/AllHeaders';
import { fetchProjects, incrementPageNo } from '../slices/projectSlice';
import Button from 'react-bootstrap/esm/Button';
import { InfinitySpin } from 'react-loader-spinner';
import { useDebounce } from 'use-debounce';
import { Link } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

const ProjectsList = () => {
    const dispatch = useDispatch();
    const { hasMore, status, projectList } = useSelector((state) => state.project);
    const [searchKeyWord, setProjectKeyword] = useState('')
    const [debouncedInput] = useDebounce(searchKeyWord, 500);


    const [buttonLoading, setButtonLoading] = useState(false); // New button loading state
    const [initialLoad, setInitialLoad] = useState(true); // Separate initial load state

    useEffect(() => {
        // Fetch data initially
        const fetchData = async () => {
            await dispatch(fetchProjects());
            setInitialLoad(false); // Set initial load to false after fetching
        };
        fetchData();
    }, [dispatch]);

    const handleViewMore = async () => {
        setButtonLoading(true); // Start button loader
        await dispatch(incrementPageNo());
        setButtonLoading(false); // Stop button loader when done
    };

    useEffect(() => {

        const fetchData = async () => {
            await dispatch(fetchProjects(debouncedInput));
        };
        fetchData();
    }, [debouncedInput, dispatch]);


    return (
        <>
            {/* <AllHeaders /> */}
            <div className="maincontent">
                <div className="container hrdashboard" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className='row'>
                        <div className='dflexbtwn pgname_btns'>
                            <div className='pagename'>
                                <h3>Projects</h3>
                                <p>Current projects information and tracking</p>
                            </div>
                            <div className='projctbtns'>
                                <Form.Group className="InputWeight" controlId="alt_email">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FiSearch />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search By Project Name....."
                                            name="project_name"
                                            value={searchKeyWord}
                                            onChange={(e) => setProjectKeyword(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Link to='/add-project' className='lt_blue sitebtn'> <LuPlus /> Add Project </Link>
                                <Link to='/close-project' className='tbtn sitebtn'> <IoMdCloseCircleOutline /> Close Project </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {initialLoad || status === 'loading' ? ( // Show loader if initial loading or status is loading
                            <div className="d-flex align-content-center justify-content-center">
                                <InfinitySpin
                                    visible={true}
                                    width="350"
                                    color="#29166F"
                                    ariaLabel="infinity-spin-loading"
                                />
                            </div>
                        ) : (
                            projectList && projectList?.data ? projectList.data.map((item) => (
                                <div className='col-sm-4' key={item._id}>
                                    <ProjectBox data={item} />
                                </div>
                            )) :
                            <div className="d-flex align-content-center justify-content-center">
                            <img
                               src='/pageNotFound.png'
                               alt='Project Not Found'
                               height={'300px'}
                               width={'300px'}
                            />
                        </div>
                        )}
                    </div>
                    <div className='row'>
                        <div className='text-center'>
                            {hasMore && status === 'succeeded' && (
                                <Button onClick={handleViewMore} disabled={buttonLoading}>
                                    {buttonLoading ? 'Loading...' : 'View More'}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectsList;
