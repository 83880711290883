import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../../config/config';
import { apiHeaderToken } from '../../../config/api_header';
import { useSelector, useDispatch } from "react-redux";
import { FetchCandidatesListById } from "../../slices/AppliedJobCandidates/JobAppliedCandidateSlice";
import { useParams } from 'react-router-dom';
import { FaRocketchat } from 'react-icons/fa';
import { CiCircleRemove } from 'react-icons/ci';
import moment from 'moment';




export default function BoadMemberListing() {

    const candidateRecords = useSelector((state) => state.appliedJobList.AppliedCandidateList)
    const loginDetails = JSON.parse(localStorage.getItem('admin_role_user')) || {}
    const [memberList, setMember] = useState(null);
    const { id } = useParams();


    console.log(candidateRecords, 'this is Candidate Date and there are Kind of Data to use and handle to solve ')



    const getOfferApprovalMemberList = async (candidateId, appliedJobId) => {
        try {
            let payloads = {
                "candidate_id": candidateId,
                "applied_job_id": appliedJobId,
            }
            let response = await axios.post(`${config.API_URL}getJobOfferApprovalMemberList`, payloads, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                setMember(response.data?.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (candidateRecords?.status === 'success') {
            getOfferApprovalMemberList(candidateRecords?.data?._id, candidateRecords?.data?.applied_jobs?.filter((item) => item.job_id === candidateRecords?.data?.job_id)[0]?._id)
        }
    }, [candidateRecords])

    console.log(memberList, 'this is Member List there are some king of Data to handle to show the');


    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard h-100 p-0 pt-4">
                        <div className="modaltbl mt-3">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Sno.</th>
                                        <th>Panel Member</th>
                                        <th>Priority</th>
                                        <th>Status</th>
                                        <th>Send Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        offerApproveMember && offerApproveMember?.length > 0 ?
                                            offerApproveMember?.map((item, index) => {
                                                return (
                                                    <tr key={item?.employee_doc_id}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className='d-flex flex-column align-items-start gap-1'>
                                                                <span>{item?.employee_code}</span>
                                                                <span>{item?.name}</span>
                                                                <span>{item?.designation}</span>
                                                            </div>
                                                        </td>
                                                        <td width={'18%'}>
                                                            <Form.Control
                                                                type="text"
                                                                value={item?.priority}
                                                                readOnly={(item?.status === "Approved" || item?.status === "Pending")}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value;
                                                                    if (/^\d*$/.test(newValue)) {
                                                                        handlePriorityChange(index, parseInt(newValue, 10) || ""); // Parse or fallback to empty
                                                                    }
                                                                }}
                                                                className="form-control w-50"
                                                            />
                                                        </td>
                                                        <td>{item?.status || "-"}</td>
                                                        <td>{item?.send_mail_date ? moment(item?.send_mail_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <span className="" style={{ height: '44px' }}>{getActionButtonByPriority(item)}</span>
                                                                {
                                                                    item?.status === "" &&
                                                                    <OverlayTrigger
                                                                        placement="top" // Tooltip position: 'top', 'bottom', 'left', or 'right'
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-delete-${item.id || Math.random()}`}>
                                                                                Delete Member
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span onClick={() => handleRemove(item)} style={{ cursor: 'pointer' }}>
                                                                            <CiCircleRemove size={25} color="red" />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className='text-center'>
                                                <td colSpan={6} className='text-center'>No Record Found</td>
                                            </tr>
                                    } */}
                                    {
                                        memberList && memberList?.length > 0 ?
                                            memberList.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={item?.employee_code}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className='d-flex flex-column align-items-start gap-1'>
                                                                    <span>{item?.employee_code}</span>
                                                                    <span>{item?.name}</span>
                                                                    <span>{item?.designation}</span>
                                                                </div>
                                                            </td>
                                                            <td>{item?.priority}</td>
                                                            <td>{item?.status}</td>
                                                            <td>{item?.send_mail_date ? moment(item?.send_mail_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                                            <td>-</td>
                                                        </tr>
                                                    </>
                                                )
                                            }) :
                                            <tr className='text-center'>
                                                <td colSpan={6} className='text-center'>No Record Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

